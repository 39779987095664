export const YOUTUBE = 'YouTube';
export const SPOTIFY = 'Spotify';
export const ZORA = 'ZORA';
export const FARCASTER = 'Farcaster'; // New
export const SPINAMP = 'Spinamp'; // New

export const EMBEDSINTERFACE = {
	YOUTUBE: 'YouTube',
	SPOTIFY: 'Spotify',
	ZORA: 'ZORA',
	// FARCASTER: 'Farcaster', // New
	// SPINAMP: 'Spinamp', // New
};
