interface Appearance {
	background: string;
	backgroundColor: string;
	font: string;
	fontColor: string;
	linkStyle: {
		filled: boolean;
		rounded: boolean;
	};
	linkColor: string;
	linkFontColor: string;
}

// interface Socials {
// 	twitter: string;
// 	instagram: string;
// 	facebook: string;
// 	linkedin: string;
// 	github: string;
// 	hashnode: string;
// 	devto: string;
// 	medium: string;
// 	whatsapp: string;
// }

interface Link {
	embed: string;
	title: string;
	link: string;
	description: string;
	active: boolean;
	linkStyle?: string;
	isWeb3: boolean;
}

interface Web2Socials {
	twitter: string;
	instagram: string;
	facebook: string;
	linkedin: string;
	github: string;
	hashnode: string;
	devto: string;
	medium: string;
	whatsapp: string;
	tiktok: string;
	pinterest: string;
}

interface Web3Socials {
	farcaster: string;
	ipfs: string;
	opensea: string;
	basename: string;
	mirror: string;
	paragraph: string;
	ens: string;
}

interface State {
	imgFile: File | null;
	bgImgFile: File | null;
	username: string;
	imgSrc: string;
	profileName: string;
	about: string;
	links: Link[];
	appearance: Appearance;
	error: string;
	loading: boolean;
	web2Socials: Web2Socials;
	web3Socials: Web3Socials;
}

const defaultWeb2Socials: Web2Socials = {
	twitter: '',
	instagram: '',
	facebook: '',
	linkedin: '',
	github: '',
	hashnode: '',
	devto: '',
	medium: '',
	whatsapp: '',
	tiktok: '',
	pinterest: '',
};

const defaultWeb3Socials: Web3Socials = {
	farcaster: '',
	ipfs: '',
	opensea: '',
	basename: '',
	mirror: '',
	paragraph: '',
	ens: '',
};

type Action =
	| { type: 'field'; field: keyof State; value: any }
	| { type: 'update' }
	| { type: 'success' }
	| { type: 'error'; error: string };

// Update the initialState to match this structure
const initialState: State = {
	imgFile: null,
	bgImgFile: null,
	username: '',
	imgSrc: '',
	profileName: '',
	about: '',
	links: [],
	web2Socials: defaultWeb2Socials,
	web3Socials: defaultWeb3Socials,
	appearance: {
		background: '',
		backgroundColor: '',
		font: '',
		fontColor: '',
		linkStyle: { filled: false, rounded: false },
		linkColor: '',
		linkFontColor: '',
	},
	error: '',
	loading: false,
};

function adminReducer(state: State, action: Action): State {
	switch (action.type) {
		case 'field':
			if (action.field === 'web2Socials' || action.field === 'web3Socials') {
				return {
					...state,
					[action.field]: {
						...state[action.field],
						...action.value,
					},
				};
			}
			return {
				...state,
				[action.field]: action.value,
			};
		case 'update':
			return {
				...state,
				loading: true,
			};
		case 'success':
			return {
				...state,
				loading: false,
				imgFile: null,
				bgImgFile: null,
			};
		case 'error':
			return {
				...state,
				error: action.error,
			};
		default:
			return state;
	}
}

export { initialState, adminReducer };
export type { Action, State, Web2Socials, Web3Socials };
