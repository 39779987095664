import * as ROUTES from "../constants/routes";

import {
  HiOutlineLink,
  HiOutlinePencil,
  HiOutlineUpload,
} from "react-icons/hi";
import { NFT_CONTRACT_ABI, NFT_CONTRACT_ADDRESS } from '../constants/contractInfo';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from "react";
import { useReadContract, useSimulateContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';

import { AiOutlineEye } from "react-icons/ai";
import Appearance from "../components/appearance";
import { BsBrush } from "react-icons/bs";
import Editor from "../components/Editor";
import Header from "../components/header";
import { ImSpinner } from "react-icons/im";
import { Link } from "react-router-dom";
import Preview from "../components/Preview";
import React from "react";
import Share from "../components/share";
import { Switch } from '@headlessui/react';
import { db } from '../lib/firebase';
import { parseEther } from 'viem';
import { useAdmin } from "../context/adminContext";
import { useAuth } from "../context/authContext";
import { useFirestore } from "../context/firestoreContext";
import { useHeader } from "../context/headerContext";

export default function Admin() {
  const { logOut, currentUser } = useAuth();
  const { userData, updateProfile, getUserDoc } = useFirestore();
  const { setCustomHeader } = useHeader();
  const { state, dispatch } = useAdmin();
  const { username, profileName, about, imgSrc, links, appearance, web2Socials, web3Socials, loading } = state;

  const [showDesign, setShowDesign] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showUsernamePopup, setShowUsernamePopup] = useState(false);
  const [showMintNFTPopup, setShowMintNFTPopup] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [isWriteLoading, setIsWriteLoading] = useState(false);
  const [hasMintedNFT, setHasMintedNFT] = useState(false);
  const [tipBalance, setTipBalance] = useState('0');
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [tippingEnabled, setTippingEnabled] = useState(false);

  const { data: simulateData } = useSimulateContract({
    address: NFT_CONTRACT_ADDRESS,
    abi: NFT_CONTRACT_ABI,
    functionName: 'mintUsername',
    args: [username],
  });

  const { data: simulateWithdrawData } = useSimulateContract({
    address: NFT_CONTRACT_ADDRESS,
    abi: NFT_CONTRACT_ABI,
    functionName: 'withdrawTips',
    args: []
  });

  const { writeContract, data: writeData, error: writeError } = useWriteContract();

  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash: writeData,
  });

  const { data: tipBalanceData, refetch: refetchTipBalance } = useReadContract({
    address: NFT_CONTRACT_ADDRESS,
    abi: NFT_CONTRACT_ABI,
    functionName: 'getTipsBalance',
    args: [username],
  });
  
  const handleToggleTipping = async () => {
    const newTippingEnabled = !tippingEnabled;
    setTippingEnabled(newTippingEnabled);
    try {
      if (currentUser?.uid) {
        await updateProfile(currentUser.uid, {
          page: {
            ...userData?.page,
          tippingEnabled: newTippingEnabled,
          },
        });
      }
    } catch (error) {
      console.error("Error updating tipping status:", error);
    }
  };
  

  useEffect(() => {
    if (hasMintedNFT && username) {
      refetchTipBalance();
    }
  }, [hasMintedNFT, username, refetchTipBalance]);

  const handleUpdate = async (event: React.FormEvent) => {
    event.preventDefault();
    dispatch({ type: "update" });
    try {
      console.log("🚀 ~ handleUpdate ~ links:", links)
      await updateProfile(userData?.userId, {
        page: {
          ...userData?.page,
          profileName: profileName,
          imgSrc: imgSrc,
          about: about,
          links: links,
          appearance: appearance,
          web2Socials: web2Socials,
          web3Socials: web3Socials,
          tippingEnabled: tippingEnabled,
        },
      });
      dispatch({ type: "success" });
    } catch (error: any) {
      dispatch({ type: "error", error: error.message });
    }
  };

  const validateUsername = (value: string) => {
    const regex = /^[a-zA-Z0-9_]{3,30}$/;
    if (!regex.test(value)) {
      return "Username must be 3-30 characters long and can only contain letters, numbers, and underscores.";
    }
    return "";
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewUsername(value);
    setUsernameError(validateUsername(value));
  };

  const handleUsernameSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const error = validateUsername(newUsername);
    if (error) {
      setUsernameError(error);
      return;
    }
    if (newUsername && userData) {
      try {
        const usernameTaken = await getUserDoc(newUsername);
        if (!usernameTaken) {
          await updateProfile(userData.userId, { username: newUsername });
          dispatch({ type: "field", field: "username", value: newUsername });
          setShowUsernamePopup(false);
          setShowMintNFTPopup(true);
        } else {
          setUsernameError("Username is already taken");
        }
      } catch (error) {
        console.error("Error updating username:", error);
        setUsernameError("An error occurred while updating the username");
      }
    }
  };

  const handleMintNFT = async () => {
    if (!username || !simulateData?.request) return;
  
    try {
      setIsWriteLoading(true);
      const result = await writeContract(simulateData.request);
      console.log("NFT minting transaction sent:", result);
    } catch (error) {
      console.error("Error minting NFT:", error);
    } finally {
      setIsWriteLoading(false);
    }
  };

  const handleWithdrawTips = async () => {
    if (!hasMintedNFT) return;
  
    try {
      const result = await writeContract(simulateWithdrawData?.request as any);
      console.log("Withdraw transaction sent:", result);
      await refetchTipBalance();
    } catch (error) {
      console.error("Error withdrawing tips:", error);
    }
  };



  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser?.uid) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const fetchedUserData = userDocSnap.data();
          const fetchedUsername = fetchedUserData.username || "";
          dispatch({ type: "field", field: "username", value: fetchedUsername });
          dispatch({ type: "field", field: "profileName", value: fetchedUserData.page?.profileName || "" });
          dispatch({ type: "field", field: "about", value: fetchedUserData.page?.about || "" });
          dispatch({ type: "field", field: "links", value: fetchedUserData.page?.links || [] });
          dispatch({ type: "field", field: "appearance", value: fetchedUserData.page?.appearance || {} });
          dispatch({ type: "field", field: "web2Socials", value: fetchedUserData.page?.web2Socials || {} });
          dispatch({ type: "field", field: "web3Socials", value: fetchedUserData.page?.web3Socials || {} });
          setHasMintedNFT(fetchedUserData.hasNFT || false);
          setTippingEnabled(fetchedUserData.page?.tippingEnabled || false);

             // Check if the username is empty and show the popup if it is
             if (!fetchedUsername) {
              setShowUsernamePopup(true);
            }
            
        }
      }
      setIsLoading(false);
    };
  
    fetchUserData();
  }, [userData, dispatch, currentUser]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
        console.error("Loading timed out");
      }
    }, 10000);
  
    return () => clearTimeout(timer);
  }, [isLoading]);

  useEffect(() => {
    const checkNFTStatus = async () => {
      if (userData && userData.hasNFT) {
        setHasMintedNFT(true);
      }
    };
    checkNFTStatus();
  }, [userData]);

  useEffect(() => {
    if (isConfirmed) {
      updateProfile(userData?.userId, { hasNFT: true });
      setShowMintNFTPopup(false);
      setHasMintedNFT(true);
    }
  }, [isConfirmed, userData?.userId, updateProfile]);

  useEffect(() => {
    if (tipBalanceData) {
      setTipBalance(tipBalanceData.toString());
    }
  }, [tipBalanceData]);

  useEffect(() => {
    const customHeader = (
      <div className="flex items-center justify-around space-x-1">
        <button
          onClick={() => logOut()}
          className="bg-carbon-black text-moonlight-white hover:bg-steel-gray rounded-3xl px-4 py-2 font-nunito font-bold"
        >
          Log Out
        </button>
        <Link
          to={`/${username}`}
          className="bg-orange-600 text-carbon-black hover:bg-orange-500 rounded-3xl px-4 py-2 font-nunito font-bold"
        >
          View Page
        </Link>
      </div>
    );
    setCustomHeader(customHeader);
  }, [setCustomHeader, username, logOut]);

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="lg:hidden">
        <Share username={username} />
      </div>
      <div className="grid w-full bg-gray-200 font-nunito dark:bg-zinc-900">
        {!showPreview ? (
          <div className="w-full p-4 pb-96">
            <div className="hidden lg:flex">
              <Share username={username} />
            </div>
            {showDesign ? <Appearance /> : <Editor />}
            {/* {hasMintedNFT && (
              <div className="mt-4 bg-white dark:bg-zinc-900 rounded-lg p-4 shadow-md">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold">Tip Balance: {tipBalance} ETH</h3>
                  <button
                    onClick={handleWithdrawTips}
                    className="bg-primary-accent text-white px-4 py-2 rounded-md hover:bg-secondary-accent transition-colors duration-300"
                  >
                    Withdraw Tips
                  </button>
                </div>
                <div className="flex items-center">
                  <span className="mr-2">Enable Tipping:</span>
                  <Switch
                    checked={tippingEnabled}
                    onChange={handleToggleTipping}
                    className={`${tippingEnabled ? 'bg-primary-accent' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Enable tipping</span>
                    <span
                      className={`${tippingEnabled ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
              </div>
            )} */}
          </div>
        ) : (
          <Preview preview={true} tippingEnabled={tippingEnabled} />
        )}
      </div>

      <div className="fixed bottom-0 z-50 flex w-full items-center justify-center rounded-t-3xl bg-white p-4 dark:bg-zinc-800">
        {showPreview ? (
          <button
            onClick={() => setShowPreview(false)}
            className="rounded-full bg-orange-600 px-4 py-2 font-nunito font-bold text-white hover:bg-secondary-accent flex items-center "
          >
            <HiOutlinePencil size={24} className="inline mr-2" />
            Editor
          </button>
        ) : (
          <>
            <button
              onClick={() => setShowDesign(!showDesign)}
              className="rounded-full bg-orange-600 px-4 py-2 font-nunito font-bold text-white hover:bg-orange-500 flex items-center mr-9"
            >
              {showDesign ? (
                <>
                  <HiOutlineLink size={24} className="inline mr-2" />
                  Links
                </>
              ) : (
                <>
                  <BsBrush size={24} className="inline mr-2" />
                  Design
                </>
              )}
            </button>
            <button
              onClick={() => setShowPreview(true)}
              className="rounded-full bg-orange-600 px-4 py-2 font-nunito font-bold text-white hover:bg-orange-500 flex items-center"
            >
              <AiOutlineEye size={24} className="inline mr-2" />
              Preview
            </button>
          </>
        )}
      </div>

      {showUsernamePopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-96 rounded-lg bg-white p-6 shadow-xl">
            <h2 className="mb-4 text-2xl font-bold">Set Your Username</h2>
            <form onSubmit={handleUsernameSubmit}>
              <div className="mb-4">
                <label htmlFor="username" className="mb-2 block text-sm font-bold text-gray-700">
                  Your Unique Link
                </label>
                <div className="flex items-center bg-gray-200 rounded-md overflow-hidden">
                  <span className="bg-gray-300 px-3 py-2 text-gray-600">baseurl.xyz/</span>
                  <input
                    type="text"
                    id="username"
                    value={newUsername}
                    onChange={handleUsernameChange}
                    className="flex-grow bg-gray-200 p-2 text-gray-800 outline-none dark:bg-primary dark:text-white"
                    placeholder="yourname"
                  />
                </div>
              </div>
              {usernameError && (
                <p className="text-sm font-semibold text-rose-400 mb-2">{usernameError}</p>
              )}
              <button
                type="submit"
                className="w-full rounded-md bg-primary-accent px-4 py-2 font-nunito font-bold text-white hover:bg-secondary-accent transition duration-300 ease-in-out transform hover:scale-105"
              >
                Set Username
              </button>
            </form>
          </div>
        </div>
      )}

{showMintNFTPopup && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="w-96 rounded-lg bg-white p-6 shadow-xl">
      <h2 className="mb-4 text-2xl font-bold">Mint Your Username NFT</h2>
      <p className="mb-4">
        Mint an NFT for your username to enable tipping and other features.
      </p>
      <div className="flex justify-between">
        <button
          onClick={handleMintNFT}
          disabled={isWriteLoading || isConfirming}
          className="w-1/2 mr-2 rounded-md bg-primary-accent px-4 py-2 font-nunito font-bold text-white hover:bg-secondary-accent transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isWriteLoading || isConfirming ? (
            <ImSpinner className="animate-spin inline-block mr-2" />
          ) : null}
          {isWriteLoading ? "Minting..." : isConfirming ? "Confirming..." : "Mint NFT"}
        </button>
        <button
          onClick={() => setShowMintNFTPopup(false)}
          className="w-1/2 ml-2 rounded-md bg-gray-300 px-4 py-2 font-nunito font-bold text-gray-700 hover:bg-gray-400 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Skip
        </button>
      </div>
      {writeError && (
        <p className="mt-2 text-sm font-semibold text-rose-400">
          Error: {writeError.message}
        </p>
      )}
    </div>
        </div>
      )}
      {showSaveButton && (
        <div className="fixed bottom-20 right-4 z-50 lg:bottom-4">
          <button
            onClick={handleUpdate}
            disabled={loading}
            className="rounded-full bg-orange-600 px-4 py-2 font-nunito font-bold text-white hover:bg-orange-500"
          >
            {loading ? (
              <ImSpinner size={24} className="animate-spin inline mr-2" />
            ) : (
              <HiOutlineUpload size={24} className="inline mr-2" />
            )}
            {loading ? "Saving" : "Save Changes"}
          </button>
        </div>
      )}
    </>
  );
}
