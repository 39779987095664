import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
	FirebaseStorage,
	connectStorageEmulator,
	getStorage,
} from 'firebase/storage';
import {
	Firestore,
	connectFirestoreEmulator,
	getFirestore,
} from 'firebase/firestore';

interface FirebaseConfig {
	apiKey: string;
	authDomain: string;
	projectId: string;
	storageBucket: string;
	messagingSenderId?: string;
	appId: string;
	measurementId?: string;
}

const firebaseConfig: FirebaseConfig = {
	apiKey: 'AIzaSyAvZIEk4weAzsody6evgJGF3EIImpWhapM',
	authDomain: 'baseurl-fefdd.firebaseapp.com',
	projectId: 'baseurl-fefdd',
	storageBucket: 'baseurl-fefdd.appspot.com',
	messagingSenderId: '315098465239',
	appId: '1:315098465239:web:20daf9b7aef02eba600192',
	measurementId: 'G-V8S6QV6DC0',
};

const hostname: string = window.location.hostname;

// Initialize Firebase
const app: FirebaseApp =
	hostname === 'localhost'
		? initializeApp({
				apiKey: 'demo-key',
				authDomain: 'demo-test',
				projectId: 'demo-test',
				storageBucket: 'default-bucket',
		  })
		: initializeApp(firebaseConfig);

export const auth: Auth = getAuth(app);
export const db: Firestore = getFirestore(app);
export const storage: FirebaseStorage = getStorage(app);

if (hostname === 'localhost') {
	connectAuthEmulator(auth, 'http://localhost:9099');
	connectFirestoreEmulator(db, 'localhost', 8188);
	connectStorageEmulator(storage, 'localhost', 9199);
}
