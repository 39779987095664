import { Action, adminReducer, initialState } from "../reducers/adminReducer";
import React, { ReactNode, createContext, useContext, useEffect, useReducer, useState } from "react";

import PropTypes from "prop-types";
import { useFirestore } from "../context/firestoreContext";

interface Appearance {
  background: string;
  backgroundColor: string;
  font: string;
  fontColor: string;
  linkStyle: {
    filled: boolean;
    rounded: boolean;
  };
  linkColor: string;
  linkFontColor: string;
}

interface Web2Socials {
  twitter: string;
  instagram: string;
  facebook: string;
  linkedin: string;
  github: string;
  hashnode: string;
  devto: string;
  medium: string;
  whatsapp: string;
  tiktok: string;
  pinterest: string;
}
interface Web3Socials {
  farcaster: string;
  ipfs: string;
  opensea: string;
  basename: string;
  mirror: string;
  paragraph: string;
  ens: string;
}

interface AdminState {
  imgFile: File | null;
  bgImgFile: File | null;
  username: string;
  imgSrc: string;
  profileName: string;
  about: string;
  links: any[]; // Adjust this type based on your actual data structure
  appearance: Appearance;
  error: string;
  loading: boolean;
  web2Socials: Web2Socials;
  web3Socials: Web3Socials;
}

interface AdminContextValue {
  state: AdminState;
  dispatch: React.Dispatch<Action>;
}

interface AdminProviderProps {
  children: ReactNode;
}

const AdminContext = createContext<AdminContextValue | undefined>(undefined);

export function useAdmin() {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error("useAdmin must be used within an AdminProvider");
  }
  return context;
}

export function AdminProvider({ children }: AdminProviderProps) {
  const { userData } = useFirestore();
  const [state, dispatch] = useReducer(adminReducer, initialState);
  const [isLoading, setIsLoading] = useState(true);

  console.log("AdminProvider initial state:", state);

  useEffect(() => {
    console.log("AdminProvider useEffect triggered");
    console.log("Received userData:", userData);
    if (userData && userData.page) {
      console.log("🚀 ~ useEffect ~ userData:", userData)
      console.log("Updating AdminProvider state with userData");
      dispatch({ type: "field", field: "username", value: userData.username || state.username });
      dispatch({ type: "field", field: "imgSrc", value: userData.page?.imgSrc || state.imgSrc });
      dispatch({ type: "field", field: "profileName", value: userData.page?.profileName || state.profileName });
      dispatch({ type: "field", field: "about", value: userData.page?.about || state.about });
      dispatch({ type: "field", field: "appearance", value: userData.page?.appearance || state.appearance });
      dispatch({ type: "field", field: "web2Socials", value: {
        ...userData.web2Socials,
        ...state.web2Socials
      }});
      dispatch({ type: "field", field: "web3Socials", value: {
        ...state.web3Socials,
        ...userData.page?.web3Socials
      }});
      dispatch({ type: "field", field: "links", value: userData.page?.links || state.links });
      console.log("AdminProvider state updated");
    } else {
      console.log("No userData available in AdminProvider");
    }
  }, [userData]);

  console.log("AdminProvider current state:", state);

  
  const value = { state, dispatch, isLoading };

  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
}

AdminProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
