import React, { useState } from "react";

import { BsQuestionCircle } from "react-icons/bs";

interface InputFieldProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  tooltip?: string;
  fieldType?: string;
}

export default function InputField({ 
  label, 
  value, 
  onChange, 
  placeholder,
  tooltip,
  fieldType = "handle" 
}: InputFieldProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative w-full">
      <div className="relative w-full rounded-xl bg-gray-100 p-2 pt-6 dark:bg-zinc-900 transition-all duration-200 hover:bg-gray-200 dark:hover:bg-zinc-800">
        <input
          type="text"
          name="name"
          required
          className="peer h-full w-full border-b-2 border-gray-300 bg-transparent text-base text-gray-800 outline-none transition-colors focus:border-blue-500 dark:border-gray-700 dark:text-white"
          value={value}
          onChange={onChange}
          // placeholder={placeholder}
        />
        <label
          className="pointer-events-none absolute left-2 top-2 text-sm text-gray-600 transition-all peer-focus:text-blue-500 dark:text-gray-400"
        >
          {label.replace('handle', fieldType)}
        </label>
        
        {tooltip && (
          <div className="absolute right-2 top-2">
            <button
              type="button"
              className="text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={() => setShowTooltip(!showTooltip)}
            >
              <BsQuestionCircle size={16} />
            </button>
            {showTooltip && (
              <div className="absolute right-0 top-6 z-10 w-64 rounded-md bg-black px-3 py-2 text-xs text-white shadow-lg">
                {tooltip}
                <div className="absolute -top-1 right-2 h-2 w-2 rotate-45 bg-black"></div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
