import React, { useState } from "react";
import { Web2Socials, Web3Socials } from "../reducers/adminReducer";

import InputField from "./commons/inputField";
import { Switch } from "@headlessui/react";
import { useAdmin } from "../context/adminContext";

type Web2SocialKeys = keyof Web2Socials;
type Web3SocialKeys = keyof Web3Socials;




const socialConfig: {
  web2: Record<Web2SocialKeys, { placeholder: string; tooltip: string; fieldType: string }>;
  web3: Record<Web3SocialKeys, { placeholder: string; tooltip: string; fieldType: string }>;
} = {
  web2: {
    twitter: {
      placeholder: "e.g. marvel_ib",
      tooltip: "Enter username that appears after twitter.com/",
      fieldType: "username"
    },
    instagram: {
      placeholder: "e.g. marvel.codes",
      tooltip: "Enter username that appears after instagram.com/",
      fieldType: "username"
    },
    facebook: {
      placeholder: "e.g. facebook.com/your.profile",
      tooltip: "Enter your complete Facebook profile URL",
      fieldType: "profile URL"
    },
    linkedin: {
      placeholder: "e.g. linkedin.com/in/your-profile",
      tooltip: "Enter your complete LinkedIn profile URL",
      fieldType: "profile URL"
    },
    github: {
      placeholder: "e.g. marvel-code",
      tooltip: "Enter username that appears after github.com/",
      fieldType: "username"
    },
    hashnode: {
      placeholder: "e.g. marvelcodes",
      tooltip: "Enter username that appears after hashnode.com/@",
      fieldType: "username"
    },
    devto: {
      placeholder: "e.g. marvelcodes",
      tooltip: "Enter username that appears after dev.to/",
      fieldType: "username"
    },
    medium: {
      placeholder: "e.g. marvelcodes",
      tooltip: "Enter username that appears after medium.com/@",
      fieldType: "username"
    },
    whatsapp: {
      placeholder: "e.g. +1234567890",
      tooltip: "Enter your phone number with country code (e.g., +1 for US)",
      fieldType: "phone number"
    },
    tiktok: {
      placeholder: "e.g. marvelcodes",
      tooltip: "Enter username that appears after tiktok.com/@",
      fieldType: "username"
    },
    pinterest: {
      placeholder: "e.g. marvelcodes",
      tooltip: "Enter username that appears after pinterest.com/",
      fieldType: "username"
    }
  },
  web3: {
    farcaster: {
      placeholder: "e.g. marvel",
      tooltip: "Enter username that appears after warpcast.com/",
      fieldType: "username"
    },
    ens: {
      placeholder: "e.g. marvel.eth",
      tooltip: "Enter your ENS domain (e.g., name.eth)",
      fieldType: "domain"
    },
    ipfs: {
      placeholder: "e.g. QmYw...",
      tooltip: "Enter your IPFS hash (starts with Qm...)",
      fieldType: "hash"
    },
    opensea: {
      placeholder: "e.g. marvel-nfts",
      tooltip: "Enter username that appears after opensea.io/",
      fieldType: "username"
    },
    basename: {
      placeholder: "e.g. marvel.base",
      tooltip: "Enter your Base name that appears after base.org/name/",
      fieldType: "name"
    },
    mirror: {
      placeholder: "e.g. marvel",
      tooltip: "Enter your Mirror address that appears after mirror.xyz/",
      fieldType: "URL"
    },
    paragraph: {
      placeholder: "e.g. marvel",
      tooltip: "Enter username that appears after paragraph.xyz/",
      fieldType: "username"
    }
  }
};

const SocialIconCard: React.FC = () => {
  const { state, dispatch } = useAdmin();
  const { web2Socials, web3Socials } = state;
  const [isWeb3, setIsWeb3] = useState(false);

  const handleSocialChange = (type: 'web2' | 'web3', field: Web2SocialKeys | Web3SocialKeys, value: string) => {
    dispatch({
      type: "field",
      field: type === 'web2' ? "web2Socials" : "web3Socials",
      value: { ...state[`${type}Socials`], [field]: value },
    });
  };

  return (
    <div className="flex w-full flex-col items-center space-y-4 rounded-3xl border border-gray-600 bg-white p-5 dark:bg-zinc-800 mt-5">
      <span className="font-nunito text-xl text-gray-800 dark:text-white">
        Social Icons
      </span>
      <div className="flex items-center gap-2">
        <Switch
          checked={isWeb3}
          onChange={setIsWeb3}
          className={`${isWeb3 ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Enable Web3 socials</span>
          <span
            className={`${isWeb3 ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
        <span className="font-nunito text-sm text-gray-600 dark:text-gray-300">
          {isWeb3 ? 'Web3 Socials' : 'Web2 Socials'}
        </span>
      </div>

      <div className="w-full space-y-4 max-h-[900px] overflow-y-auto px-2">
        {isWeb3 ? (
          Object.entries(web3Socials).map(([key, value]) => (
            <InputField
              key={key}
              label={key}
              value={value}
              onChange={(e) => handleSocialChange('web3', key as Web3SocialKeys, e.target.value)}
              placeholder={socialConfig.web3[key as Web3SocialKeys].placeholder}
              tooltip={socialConfig.web3[key as Web3SocialKeys].tooltip}
              fieldType={socialConfig.web3[key as Web3SocialKeys].fieldType}
            />
          ))
        ) : (
          Object.entries(web2Socials).map(([key, value]) => (
            <InputField
              key={key}
              label={key}
              value={value}
              onChange={(e) => handleSocialChange('web2', key as Web2SocialKeys, e.target.value)}
              placeholder={socialConfig.web2[key as Web2SocialKeys].placeholder}
              tooltip={socialConfig.web2[key as Web2SocialKeys].tooltip}
              fieldType={socialConfig.web2[key as Web2SocialKeys].fieldType}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default SocialIconCard;
