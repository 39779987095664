import { User, signInWithCustomToken } from 'firebase/auth';
import { auth, db } from '../lib/firebase';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';

interface AuthResult {
	user: any; // Firestore user data
	firebaseUser: User;
}

async function getCustomToken(address: string): Promise<string> {
	const response = await fetch('https://api.easelite.com/general/wallet', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ address }),
	});

	if (!response.ok) {
		throw new Error('Failed to get custom token');
	}

	const { token } = await response.json();
	return token;
}

export async function signInWithWallet(address: string): Promise<AuthResult> {
	const token = await getCustomToken(address);
	const userCredential = await signInWithCustomToken(auth, token);
	const firebaseUser = userCredential.user;

	const userDocRef = doc(db, 'users', firebaseUser.uid);
	const userDoc = await getDoc(userDocRef);

	let userData;

	if (userDoc.exists()) {
		userData = userDoc.data();
	} else {
		userData = {
			userId: firebaseUser.uid,
			walletAddress: address,
			username: '',
			email: '',
			dateCreated: serverTimestamp(),
			page: {
				imgSrc: '',
				profileName: '',
				about: '',
				links: [],
				appearance: {
					background: '',
					backgroundColor: '#f3f4f6',
					font: 'Nunito',
					fontColor: '#000',
					linkStyle: {
						rounded: true,
						filled: true,
						shadow: false,
						special: '',
					},
					linkColor: '#fff',
					linkFontColor: '#000',
				},
			},
		};
		await setDoc(userDocRef, userData);
	}

	return { user: userData, firebaseUser };
}
