import * as ROUTES from "../constants/routes";

import React, { useEffect } from "react";

import Footer from "../components/footer";
import Header from "../components/header";
import { Link } from "react-router-dom";
import backgroundImage from "../images/back4.jpg";
import { useAuth } from "../context/authContext";
import { useHeader } from "../context/headerContext";

export default function Home() {
  const { currentUser, logOut, isLoading } = useAuth();
  const { setCustomHeader } = useHeader();

  useEffect(() => {
    const customHeader = currentUser ? (
      <div className="flex items-center justify-around space-x-1 rounded-xl">
        <button
          onClick={() => logOut()}
          className="border p-4 text-moonlight-white hover:bg-steel-gray rounded-3xl px-4 py-2 font-nunito font-bold"
        >
          Log Out
        </button>
        <Link
          to={ROUTES.ADMIN}
          className="bg-neon-coral text-carbon-black hover:bg-electric-cyan rounded-3xl px-4 py-2 font-nunito font-bold"
        >
          Admin
        </Link>
      </div>
    ) : (
      <div className="flex items-center justify-around space-x-1 rounded-xl">
        <Link
          to={ROUTES.LOGIN}
          className="border p-4 text-moonlight-white hover:bg-steel-gray rounded-3xl px- py-2 font-nunito font-bold"
        >
          Log In
        </Link>
        <Link
          to={ROUTES.REGISTER}
          className="bg-neon-coral text-carbon-black hover:bg-electric-cyan rounded-3xl px-4 py-2 font-nunito font-bold"
        >
          Sign Up
        </Link>
      </div>
    );

    setCustomHeader(customHeader);
  }, [currentUser, logOut, setCustomHeader]);

  if (isLoading)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <div className="loader" />
      </div>
    );

  return (
    <div className="bg-carbon-black text-moonlight-white w-full">
      <section
        className="h-screen bg-cover bg-center"
        style={{
          backgroundImage: `url('${backgroundImage}')`,
        }}
      >
        <Header />
        <section className="hero-section flex flex-col items-center justify-center p-8 text-center lg:flex-col lg:justify-between rounded-2xl">
          <div className="hero-text max-w-5xl mt-60">
            <h1 className="text-5xl font-bold lg:text-7xl">
              Unify Your Digital Presence Across Web2 and Web3
            </h1>
            <p className="text-steel-gray mt-4 text-xl lg:text-xl">
              Showcase, connect, and monetize your content with ease, for
              individuals, creators, and businesses alike.
            </p>
            <div className="mt-16 flex flex-col justify-center space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
              <div className="relative inline-flex  group">
                <div className="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-7xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
                <Link
                  to={ROUTES.REGISTER}
                  className="relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-2xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  Get Started for Free
                </Link>
              </div>
              {/* <Link
                to={ROUTES.LOGIN}
                className="text-steel-gray font-nunito text-lg font-semibold hover:underline"
              >
                Already Have an Account? Log In
              </Link> */}
            </div>
          </div>
          {/* <div className="hero-image mt-8 lg:mt-0 lg:w-1/2">
          <img
            className="w-full rounded-lg shadow-lg"
            src="./images/new-hero-image.png"
            alt="Web3 integration illustration"
          />
        </div> */}
        </section>
      </section>

      {/* Additional sections can be added here */}

      {/* <Footer /> */}
    </div>
  );
}
