import React, { useState } from 'react';

import { IoCopyOutline } from "react-icons/io5";

// Keep the imports but comment out what's not needed
// import { useAccount, useConnect, useWriteContract } from 'wagmi';
// import { parseEther } from 'viem';
// import { NFT_CONTRACT_ABI, NFT_CONTRACT_ADDRESS } from '../constants/contractInfo';

interface TipModalProps {
  isOpen: boolean;
  onClose: () => void;
  username: string;
  hasNFT: boolean;
  tippingEnabled: boolean;
}

const TipModal: React.FC<TipModalProps> = ({ 
  isOpen, 
  onClose, 
  username, 
  hasNFT, 
  tippingEnabled 
}) => {
  // Keep original state but comment out
  // const [tipAmount, setTipAmount] = useState('');
  // const { address } = useAccount();
  // const { connect, connectors } = useConnect();
  // const { writeContract } = useWriteContract();
  const [showCopiedToast, setShowCopiedToast] = useState(false);

  // Hardcoded PYUSD address
  const PYUSD_ADDRESS = "9uPgTaEN54DJ9gXJwffbBziogXPaidh45bzauy3QecLN"; // Replace with actual address

  // Comment out original handleTip
  /*
  const handleTip = async () => {
    if (!address || !hasNFT || !tippingEnabled) return;

    try {
      await writeContract({
        address: NFT_CONTRACT_ADDRESS,
        abi: NFT_CONTRACT_ABI,
        functionName: 'sendTip',
        args: [username],
        value: parseEther(tipAmount),
      });
      alert('Tip sent successfully!');
      setTipAmount('');
      onClose();
    } catch (error) {
      console.error('Error sending tip:', error);
      alert('Failed to send tip. Please try again.');
    }
  };
  */

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(PYUSD_ADDRESS);
    setShowCopiedToast(true);
    setTimeout(() => setShowCopiedToast(false), 2000);
  };

  const handleProceed = () => {
    window.open(`https://baseurl-tip.vercel.app/tip?type=admin&address=${PYUSD_ADDRESS}`, '_blank');
  };
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      {showCopiedToast && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-4 py-2 rounded-md shadow-lg">
          Address copied to clipboard!
        </div>
      )}
      
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-md w-full mx-4">
        <h2 className="text-xl font-bold mb-4 dark:text-white">Tip {username} in PYUSD</h2>
        
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            PYUSD Address
          </label>
          <div className="flex items-center bg-gray-100 dark:bg-gray-700 rounded-md p-2">
            <input
              type="text"
              value={PYUSD_ADDRESS}
              readOnly
              className="flex-1 bg-transparent outline-none text-sm dark:text-white"
            />
            <button
              onClick={handleCopyAddress}
              className="ml-2 p-2 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-full transition-colors"
            >
              <IoCopyOutline size={20} className="text-gray-600 dark:text-gray-300" />
            </button>
          </div>
        </div>

        <div className="space-y-3">
          <button
            onClick={handleProceed}
            className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
          >
            Proceed with Payment
          </button>
          <button
            onClick={onClose}
            className="w-full bg-gray-300 text-gray-700 dark:bg-gray-700 dark:text-gray-300 px-4 py-2 rounded-md hover:bg-gray-400 dark:hover:bg-gray-600 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TipModal;
