import React, { ReactNode, createContext, useContext, useState } from "react";

import PropTypes from "prop-types";

interface HeaderContextValue {
  customHeader: ReactNode;
  setCustomHeader: React.Dispatch<React.SetStateAction<ReactNode>>;
}

interface HeaderProviderProps {
  children: ReactNode;
}

const HeaderContext = createContext<HeaderContextValue | undefined>(undefined);

export function useHeader() {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error("useHeader must be used within a HeaderProvider");
  }
  return context;
}

export function HeaderProvider({ children }: HeaderProviderProps) {
  const [customHeader, setCustomHeader] = useState<ReactNode>(null);

  const value = { customHeader, setCustomHeader };

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
}

HeaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
