import { FirestoreContextValue, useFirestore } from './firestoreContext';
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import {
  User,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth, db } from "../lib/firebase";
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';

import { signInWithWallet } from '../lib/walletAuthService';

interface AuthContextValue {
  isLoading: boolean;
  currentUser: User | null;
  userData: any | null;
  signup: (email: string, password: string) => Promise<any>;
  login: (email: string, password: string) => Promise<any>;
  logOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  linkWallet: (address: string) => Promise<void>;
  loginWithWalletAndSetUser: (address: string) => Promise<any>;
  signUpOrLoginWithWallet: (address: string) => Promise<any>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [isLoading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<any | null>(null);

  const { linkWalletToUser } = useFirestore();

  useEffect(() => {
    console.log("AuthProvider useEffect triggered");
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("Auth state changed, user:", user);
      setCurrentUser(user);
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            const userData = doc.data();
            console.log("User document fetched:", userData);
            setUserData(userData);
          } else {
            console.log("No user document found");
            setUserData(null);
          }
          setLoading(false);
        });
      } else {
        console.log("No user logged in");
        setUserData(null);
        setLoading(false);
      }
    });
  
  
    return unsubscribe;
  }, []);

  async function linkWallet(address: string) {
    if (!currentUser) throw new Error("No user logged in");
    await linkWalletToUser(currentUser.uid, address);
    const userDocRef = doc(db, "users", currentUser.uid);
    await updateDoc(userDocRef, { walletAddress: address });
  }

  async function loginWithWalletAndSetUser(address: string) {
    try {
      const { user, firebaseUser } = await signInWithWallet(address);
      setCurrentUser(firebaseUser);
      setUserData(user);
      return user;
    } catch (error) {
      console.error("Failed to login with wallet:", error);
      throw error;
    }
  }

  async function signUpOrLoginWithWallet(address: string) {
    return loginWithWalletAndSetUser(address);
  }

  function signup(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function resetPassword(email: string) {
    return sendPasswordResetEmail(auth, email);
  }

  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logOut() {
    setUserData(null);
    return signOut(auth);
  }

  const value = {
    isLoading,
    currentUser,
    userData,
    signup,
    login,
    logOut,
    resetPassword,
    linkWallet,
    loginWithWalletAndSetUser,
    signUpOrLoginWithWallet,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
