import "react-farcaster-embed/dist/styles.css";

import * as EMBED from "../constants/embed";

import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

interface EmbedProps {
  link: {
    embed: string;
    link: string;
    title: string;
  };
  linkStyle: {
    filled: boolean;
    rounded: boolean;
  };
  linkColor: string;
  linkFontColor: string;
}

export default function Embed({ link, linkStyle, linkColor, linkFontColor }: EmbedProps) {
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/;

  const spotifyRegex = /(?<=(com\/))(?:...)+/;

  const zoraRegex = /https:\/\/zora\.co\/collect\/([\w:]+)\/(\d+)/;

  // Regex to match Spinamp links
  const spinampRegex = /https:\/\/app\.spinamp\.xyz\/track\/([\w-]+)-(\d+)/;

  if (link.embed === EMBED.YOUTUBE && !!youtubeRegex.exec(link.link))
    return (
      <a
        rel="noreferrer"
        href={`https://youtube.com/watch?v=${youtubeRegex.exec(link.link)![1]}`}
        target="_blank"
        style={{
          background: linkStyle.filled ? linkColor : "",
          border: linkStyle.filled ? "none" : `2px solid ${linkColor}`,
          color: linkFontColor,
        }}
        className={`flex min-h-[4rem] w-full max-w-3xl flex-col items-center justify-center p-2 ${
          linkStyle.rounded ? "rounded-3xl" : "rounded-md"
        } cursor-pointer space-y-2 transition-transform duration-300 hover:scale-105`}
      >
        <img
          src={`https://img.youtube.com/vi/${
            youtubeRegex.exec(link.link)![1]
          }/hqdefault.jpg`}
          alt="thumbnail"
          className="w-32 rounded-xl"
        />
        <p className="max-w-2xl text-center text-sm font-light">{link.title}</p>
      </a>
    );

  if (link.embed === EMBED.SPOTIFY && !!spotifyRegex.exec(link.link))
    return (
      <div
        style={{
          background: linkStyle.filled ? linkColor : "",
          border: linkStyle.filled ? "none" : `2px solid ${linkColor}`,
          color: linkFontColor,
        }}
        className={`flex min-h-[4rem] w-full max-w-3xl flex-col items-center justify-center p-4 ${
          linkStyle.rounded ? "rounded-3xl" : "rounded-md"
        } space-y-2 transition-transform duration-300 hover:scale-105`}
      >
        <iframe
          style={{ borderRadius: "12px" }}
          src={`https://open.spotify.com/embed/${
            spotifyRegex.exec(link.link)![0]
          }`}
          width="100%"
          height="80"
          frameBorder="0"
        ></iframe>
        <p className="max-w-2xl text-center text-sm font-light">{link.title}</p>
      </div>
    );

  if (link.embed === EMBED.ZORA && !!zoraRegex.exec(link.link)) {
    const [, base, id] = zoraRegex.exec(link.link)!;
    return (
      <div
        style={{
          background: linkStyle.filled ? linkColor : "",
          border: linkStyle.filled ? "none" : `2px solid ${linkColor}`,
          color: linkFontColor,
          padding: "1rem",
          borderRadius: linkStyle.rounded ? "1.5rem" : "0.5rem",
          maxWidth: "100%",
          textAlign: "center",
        }}
        className="flex flex-col items-center justify-center space-y-2 transition-transform duration-300 hover:scale-105"
      >
        <div style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}>
          <iframe
            src={`https://zora.co/collect/${base}/${id}/embed`}
            style={{
              border: 0,
              backgroundColor: "transparent",
              position: "absolute",
              inset: 0,
              width: "100%",
              height: "100%",
              borderRadius: "12px",
            }}
            allowTransparency={true}
            allowFullScreen={false}
            sandbox="allow-pointer-lock allow-same-origin allow-scripts allow-popups"
          ></iframe>
        </div>
        <p
          style={{
            margin: "0.5rem 0",
            fontSize: "0.75rem",
            lineHeight: "1rem",
            color: "#b3b3b3",
          }}
        >
          {link.title} on Zora
        </p>
      </div>
    );
  }


  // if (link.embed === EMBED.SPINAMP && !!spinampRegex.exec(link.link)) {
  //   const [, title, id] = spinampRegex.exec(link.link)!;
  //   console.log("🚀 ~ Embed ~ id:", id)
  //   console.log("🚀 ~ Embed ~ title:", title)
  //   return (
  //     <div style={{ maxWidth: "100vh" }}>
  //       <div style={{ left: 0, width: "100%", height: 0, position: "relative", paddingBottom: "100%" }}>
  //         <iframe
  //           src={`https://webapp.spinamp.xyz/embed/player/${title}-${id}`}
  //           style={{ top: 0, left: 0, width: "100%", height: "100%", position: "absolute", border: 0 }}
  //           allowFullScreen
  //         ></iframe>
  //       </div>
  //     </div>
  //   );
  // }

  

  return (
    <div
      style={{
        background: linkStyle.filled ? linkColor : "",
        border: linkStyle.filled ? "none" : `2px solid ${linkColor}`,
        color: linkFontColor,
      }}
      className={`flex min-h-[4rem] w-full max-w-3xl flex-col items-center justify-center p-4 ${
        linkStyle.rounded ? "rounded-3xl" : "rounded-md"
      } space-y-2 transition-transform duration-300 hover:scale-105`}
    >
      Invalid Link
    </div>
    
  );
}

Embed.propTypes = {
  link: PropTypes.object.isRequired,
  linkStyle: PropTypes.object,
  linkColor: PropTypes.string,
  linkFontColor: PropTypes.string,
};
