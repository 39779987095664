import { FaDev, FaEthereum, FaMedium, FaPinterest, FaTiktok } from "react-icons/fa";
import { FiFacebook, FiGithub, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";
import React, { useState } from 'react';
import { SiFarcaster, SiHashnode, SiIpfs, SiLens, SiOpensea } from "react-icons/si";

import { BsPersonFill } from "react-icons/bs";
import { EMBEDSINTERFACE } from '../constants/embed';
import Embed from "./Embed";
import { ImWhatsapp } from "react-icons/im";
import { Link } from "react-router-dom";
import LinkCard from "../components/LinkCard";
import { Switch } from '@headlessui/react';
import TipModal from './TipModal';
import { useAccount } from 'wagmi';
import { useFirestore } from '../context/firestoreContext';

interface Link {
  title: string;
  link: string;
  description?: string;
  active?: boolean;
  embed?: string;
  isWeb3?: boolean;
}

interface Web2Socials {
  twitter?: string;
  instagram?: string;
  facebook?: string;
  linkedin?: string;
  github?: string;
  hashnode?: string;
  devto?: string;
  medium?: string;
  whatsapp?: string;
  tiktok?: string;
  pinterest?: string;
}

interface Web3Socials {
  farcaster?: string;
  ens?: string;
  ipfs?: string;
  opensea?: string;
  basename?: string;
  mirror?: string;
  paragraph?: string;
}

interface Appearance {
  background?: string;
  backgroundColor?: string;
  font?: string;
  fontColor?: string;
  linkStyle?: {
    filled: boolean;
    rounded: boolean;
  };
  linkColor?: string;
  linkFontColor?: string;
}

interface PageProps {
  username: string;
  imgSrc?: string;
  profileName?: string;
  about?: string;
  links: Link[];
  appearance: Appearance;
  web2Socials: Web2Socials;
  web3Socials: Web3Socials;
  styleClasses: string;
  hasNFT: boolean;
  tippingEnabled: boolean;
}

const Page: React.FC<PageProps> = ({
  username,
  imgSrc,
  profileName,
  about,
  links,
  appearance,
  web2Socials,
  web3Socials,
  styleClasses,
  hasNFT,
  tippingEnabled,
}) => {
  const {
    background,
    backgroundColor,
    font,
    fontColor,
    linkStyle = { filled: false, rounded: false },
    linkColor = "#000",
    linkFontColor = "#000",
  } = appearance;

  const [showWeb3, setShowWeb3] = useState(false);
  const [showTipModal, setShowTipModal] = useState(false);
  const { address } = useAccount();
  const web2Links = links.filter(link => !link.isWeb3);
  const web3Links = links.filter(link => link.isWeb3);
  const { userData } = useFirestore();
  const web2Embeds = [EMBEDSINTERFACE.YOUTUBE, EMBEDSINTERFACE.SPOTIFY];
  const web3Embeds = [EMBEDSINTERFACE.ZORA];

  const getAboutFontSize = (text: string) => {
    if (text.length > 200) return 'text-xs';
    if (text.length > 100) return 'text-sm';
    return 'text-base';
  };

  console.log("🚀 ~ links:", web2Links)
  console.log("🚀 ~ web3Links:", web3Links) 

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundColor: backgroundColor,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        fontFamily: font,
        color: fontColor,
      }}
      className={`${styleClasses} min-h-screen flex flex-col items-center justify-start py-6 px-4 sm:px-6 lg:px-8`}
    >
      <div className="w-full max-w-md flex flex-col items-center space-y-4">
        {imgSrc ? (
          <div className="flex h-20 w-20 justify-center">
            <img
              src={imgSrc}
              className="h-20 w-20 rounded-full object-cover shadow-md"
              alt="Profile"
            />
          </div>
        ) : (
          <div className="flex h-20 w-20 justify-center rounded-full shadow-md bg-gray-100">
            <BsPersonFill className="h-full w-full p-4 text-gray-400" />
          </div>
        )}
        {profileName ? (
          <h1 className="text-xl font-bold text-center">{profileName}</h1>
        ) : (
          <h1 className="text-xl font-bold text-center">@{username}</h1>
        )}
        <p className={`text-center ${getAboutFontSize(about || '')} font-medium max-w-xs`}>{about}</p>
        
        {hasNFT && tippingEnabled && (
          <button
            onClick={() => setShowTipModal(true)}
            className="mt-2 w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
          >
            Tip User
          </button>
        )}

        <div className="flex items-center justify-center space-x-2 bg-white dark:bg-gray-800 rounded-full px-3 py-1 shadow-sm">
          <span className="text-xs font-medium relative group cursor-help">
            Web2
            <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded py-1 px-2 -bottom-10 left-1/2 transform -translate-x-1/2 w-40 text-center z-10">
              Traditional internet platforms
            </span>
          </span>
          <Switch
            checked={showWeb3}
            onChange={setShowWeb3}
            className={`${showWeb3 ? 'bg-indigo-600' : 'bg-gray-300'} relative inline-flex h-5 w-10 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            <span className="sr-only">Toggle Web3</span>
            <span
              className={`${showWeb3 ? 'translate-x-5' : 'translate-x-1'} inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
            />
          </Switch>
          <span className="text-xs font-medium relative group cursor-help">
            Web3
            <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded py-1 px-2 -bottom-10 left-1/2 transform -translate-x-1/2 w-40 text-center z-10">
              Decentralized blockchain internet
            </span>
          </span>
        </div>

        <div className="flex flex-wrap justify-center items-center gap-4 my-4">
          {showWeb3 ? (
           <>
            {web3Socials.farcaster && (
              <a rel="noreferrer" target="_blank" href={`https://warpcast.com/${web3Socials.farcaster}`} className="transition-transform duration-200 hover:scale-110">
                <SiFarcaster size={24} className="text-gray-700 dark:text-gray-300" />
              </a>
            )}
            {web3Socials.ens && (
              <a rel="noreferrer" target="_blank" href={`https://app.ens.domains/${web3Socials.ens}`} className="transition-transform duration-200 hover:scale-110">
                <FaEthereum size={24} className="text-gray-700 dark:text-gray-300" />
              </a>
            )}
            {web3Socials.ipfs && (
              <a rel="noreferrer" target="_blank" href={`https://ipfs.io/ipfs/${web3Socials.ipfs}`} className="transition-transform duration-200 hover:scale-110">
                <SiIpfs size={24} className="text-gray-700 dark:text-gray-300" />
              </a>
            )}
            {web3Socials.opensea && (
              <a rel="noreferrer" target="_blank" href={`https://opensea.io/${web3Socials.opensea}`} className="transition-transform duration-200 hover:scale-110">
                <SiOpensea size={24} className="text-gray-700 dark:text-gray-300" />
              </a>
            )}
            {web3Socials.basename && (
              <a rel="noreferrer" target="_blank" href={`https://www.base.org/name/${web3Socials.basename}`} className="transition-transform duration-200 hover:scale-110">
                <span className="text-gray-700 dark:text-gray-300">Basename</span>
              </a>
            )}
            {web3Socials.mirror && (
              <a rel="noreferrer" target="_blank" href={`https://mirror.xyz/${web3Socials.mirror}`} className="transition-transform duration-200 hover:scale-110">
                <span className="text-gray-700 dark:text-gray-300">Mirror</span>
              </a>
            )}
            {web3Socials.paragraph && (
              <a rel="noreferrer" target="_blank" href={`https://paragraph.xyz/${web3Socials.paragraph}`} className="transition-transform duration-200 hover:scale-110">
                <span className="text-gray-700 dark:text-gray-300">Paragraph</span>
              </a>
            )}
           </>
          ) : (
            <>
              {web2Socials.twitter && (
                <a rel="noreferrer" target="_blank" href={`https://twitter.com/${web2Socials.twitter}`} className="transition-transform duration-200 hover:scale-110">
                  <FiTwitter size={24} className="text-gray-700 dark:text-gray-300" />
                </a>
              )}
              {web2Socials.instagram && (
                <a rel="noreferrer" target="_blank" href={`https://instagram.com/${web2Socials.instagram}`} className="transition-transform duration-200 hover:scale-110">
                  <FiInstagram size={24} className="text-gray-700 dark:text-gray-300" />
                </a>
              )}
              {web2Socials.facebook && (
                <a rel="noreferrer" target="_blank" href={web2Socials.facebook} className="transition-transform duration-200 hover:scale-110">
                  <FiFacebook size={24} className="text-gray-700 dark:text-gray-300" />
                </a>
              )}
              {web2Socials.linkedin && (
                <a rel="noreferrer" target="_blank" href={web2Socials.linkedin} className="transition-transform duration-200 hover:scale-110">
                  <FiLinkedin size={24} className="text-gray-700 dark:text-gray-300" />
                </a>
              )}
              {web2Socials.github && (
                <a rel="noreferrer" target="_blank" href={`https://github.com/${web2Socials.github}`} className="transition-transform duration-200 hover:scale-110">
                  <FiGithub size={24} className="text-gray-700 dark:text-gray-300" />
                </a>
              )}
              {web2Socials.hashnode && (
                <a rel="noreferrer" target="_blank" href={`https://hashnode.com/@${web2Socials.hashnode}`} className="transition-transform duration-200 hover:scale-110">
                  <SiHashnode size={24} className="text-gray-700 dark:text-gray-300" />
                </a>
              )}
              {web2Socials.devto && (
                <a rel="noreferrer" target="_blank" href={`https://dev.to/${web2Socials.devto}`} className="transition-transform duration-200 hover:scale-110">
                  <FaDev size={24} className="text-gray-700 dark:text-gray-300" />
                </a>
              )}
              {web2Socials.medium && (
                <a rel="noreferrer" target="_blank" href={`https://medium.com/@${web2Socials.medium}`} className="transition-transform duration-200 hover:scale-110">
                  <FaMedium size={24} className="text-gray-700 dark:text-gray-300" />
                </a>
              )}
              {web2Socials.whatsapp && (
                <a rel="noreferrer" target="_blank" href={`https://api.whatsapp.com/send?phone=${web2Socials.whatsapp}`} className="transition-transform duration-200 hover:scale-110">
                  <ImWhatsapp size={24} className="text-gray-700 dark:text-gray-300" />
                </a>
              )}
              {web2Socials.tiktok && (
                <a rel="noreferrer" target="_blank" href={`https://tiktok.com/@${web2Socials.tiktok}`} className="transition-transform duration-200 hover:scale-110">
                  <FaTiktok size={24} className="text-gray-700 dark:text-gray-300" />
                </a>
              )}
              {web2Socials.pinterest && (
                <a rel="noreferrer" target="_blank" href={`https://pinterest.com/${web2Socials.pinterest}`} className="transition-transform duration-200 hover:scale-110">
                  <FaPinterest size={24} className="text-gray-700 dark:text-gray-300" />
                </a>
              )}
            </>
          )}
        </div>

        <div className="w-full space-y-3">
        
{(showWeb3 ? web3Links : web2Links)
  .filter((link) => link.active !== false && link.title && link.link && !link.embed)
  .map((link) => {
    console.log("🚀 ~ .map ~ link:", link)
    console.log("🚀 ~ .map ~ showWeb3:", showWeb3)
    return (
    <LinkCard
      key={link.title}
      link={{ ...link, description: link.description || '' }}
      linkStyle={linkStyle}
      linkColor={linkColor}
      linkFontColor={linkFontColor}
    />
    )
})}

{(showWeb3 ? web3Links : web2Links)
  .filter((link) => link.active !== false && link.title && link.link && link.embed)
  .map((link) => {
    console.log("🚀 ~ link:", web2Links)
    console.log("🚀 ~ .map ~ showWeb3:", showWeb3)
    const isWeb2Embed = web2Embeds.includes(link?.embed || '');
    console.log("🚀 ~ isWeb2Embed:", isWeb2Embed)
    const isWeb3Embed = web3Embeds.includes(link?.embed || '');
    console.log("🚀 ~ isWeb3Embed:", isWeb3Embed)

    if (showWeb3 && isWeb3Embed) {
      return (
        <Embed
          key={link.title}
          link={{ ...link, embed: link.embed || '' }}
          linkColor={linkColor}
          linkFontColor={linkFontColor}
          linkStyle={linkStyle}
        />
      );
    }else if(!showWeb3 && isWeb2Embed){
      return (
        <Embed
          key={link.title}
          link={{ ...link, embed: link.embed || '' }}
          linkColor={linkColor}
          linkFontColor={linkFontColor}
          linkStyle={linkStyle}
        />
      );
    }
    return null;
  })}
         </div>
      </div>

      <Link
        to={"/"}
        className="mt-6 text-sm font-semibold text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
      >
        Powered by BaseUrl
      </Link>

      <TipModal
        isOpen={showTipModal}
        onClose={() => setShowTipModal(false)}
        username={username}
        hasNFT={hasNFT}
        tippingEnabled={tippingEnabled}
      />
    </div>
  );
}

export default Page;
