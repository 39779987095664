import { User, onAuthStateChanged } from "firebase/auth";
import { auth, db, storage } from "../lib/firebase";
import { collection, doc, getDoc, getDocs, limit, onSnapshot, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";

import { DocumentData } from "firebase/firestore";

export interface FirestoreContextValue {
  userData: any;
  isLoading: boolean;
  storage: any;
  createUser: (user: User) => Promise<void>;
  createUserWithWallet: (address: string, username: string) => Promise<any>;
  updateProfile: (userId: string, data: any) => Promise<void>;
  getUserDoc: (username: string) => Promise<DocumentData | null>;
  linkWalletToUser: (userId: string, address: string) => Promise<void>;
  loginWithWallet: (address: string) => Promise<any>;
  getOrCreateUserByWallet: (address: string) => Promise<any>;
  hasWalletAddress: (userId: string) => Promise<boolean>;
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  updateNFTStatus: (userId: string, hasNFT: boolean) => Promise<void>;
}

const FirebaseContext = createContext<FirestoreContextValue | undefined>(undefined);

export function useFirestore() {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error("useFirestore must be used within a FirestoreProvider");
  }
  return context;
}

interface FirestoreProviderProps {
  children: React.ReactNode;
}

export function FirestoreProvider({ children }: FirestoreProviderProps) {
  const [userData, setUserData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const unsubscribeUser = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            setUserData(doc.data());
          } else {
            setUserData(null);
          }
          setIsLoading(false);
        });
        return () => unsubscribeUser();
      } else {
        setUserData(null);
        setIsLoading(false);
      }
    });
  
    return () => unsubscribe();
  }, []);

  async function createUser(user: User) {
    await setDoc(doc(db, "users", user.uid), {
      userId: user.uid,
      email: user.email,
      dateCreated: serverTimestamp(),
    });
  }

  function updateProfile(userId: string, data: any) {
    const DocRef = doc(db, "users", `${userId}`);
    return updateDoc(DocRef, data);
  }

  async function createUserWithWallet(address: string, username: string) {
    const newUser = {
      userId: address,
      walletAddress: address,
      username: username,
      email: "",
      dateCreated: serverTimestamp(),
      hasNFT: false,
      page: {
        imgSrc: "",
        profileName: "",
        about: "",
        links: [],
        appearance: {
          background: "",
          backgroundColor: "#f3f4f6",
          font: "Nunito",
          fontColor: "#000",
          linkStyle: {
            rounded: true,
            filled: true,
            shadow: false,
            special: "",
          },
          linkColor: "#fff",
          linkFontColor: "#000",
        },
      }
    };
    await setDoc(doc(db, "users", address), newUser);
    return newUser;
  }

  async function getUserDoc(username: string) {
    let userDoc: DocumentData | null = null;

    const ref = collection(db, "users");
    const q = query(ref, where("username", "==", `${username}`), limit(1));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      if (doc.exists()) userDoc = doc.data().page;
    });

    return userDoc;
  }

  async function linkWalletToUser(userId: string, address: string) {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { walletAddress: address });
  }

  async function hasWalletAddress(userId: string): Promise<boolean> {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);
    return userDoc.exists() && userDoc.data().walletAddress !== "";
  }

  async function loginWithWallet(address: string) {
    const userRef = doc(db, "users", address);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      return userDoc.data();
    }
    return null;
  }

  async function getOrCreateUserByWallet(address: string) {
    const userDoc = await loginWithWallet(address);
    if (userDoc) {
      return userDoc;
    }
    return createUserWithWallet(address, `user_${address.slice(0, 8)}`);
  }

  async function updateNFTStatus(userId: string, hasNFT: boolean) {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { hasNFT });
  }

  async function updateTippingStatus(userId: string, tippingEnabled: boolean) {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { tippingEnabled });
  }


  const value = {
    userData,
    isLoading,
    storage,
    createUser,
    createUserWithWallet,
    updateProfile,
    getUserDoc,
    linkWalletToUser,
    loginWithWallet,
    getOrCreateUserByWallet,
    hasWalletAddress,
    setUserData,
    updateNFTStatus,
    updateTippingStatus,
  };

  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
}
